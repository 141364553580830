<!--
TODO:
  Roll the dice
  Move position on board
  Give victory points automatically
-->
<template>
  <div class="text-center">
    <!-- gm -->
    <div v-if="gm" class="mt-3">
      <div v-if="turn" class="container" style="width:500px;">
        <div class="row">
          <div class="col midletter">
            <div>&nbsp;</div>
            <div v-for="l in ['A', 'B', 'C']" v-bind:key="'l'+l" v-bind:class="{'correct':turn.state == 2 && turn.answers.includes(l)}">{{l}}</div>
          </div>
          <div class="col-8">
            <img class="midpicture" v-if="turn.picture" :src="turn.picture"/>
          </div>
          <div class="col midletter">
            <div>&nbsp;</div>
            <div v-for="l in ['F', 'E', 'D']" v-bind:key="'l'+l" v-bind:class="{'correct':turn.state == 2 && turn.answers.includes(l)}">{{l}}</div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div v-for="(u, index) in users" v-bind:key="u.id" class="user">
          <div v-bind:class="{'toomany': u.answers.length > 3}">
            {{ u.id }}
            <button v-if="gmEdit" class="mr-2 badge btn-outline-secondary" @click="gmDelete(u.id)">x</button>
          </div>
          <div>
            {{ u.score }} points
          </div>
          <div v-if="turn.state == 2">
            <input ref="addPoints" type="number"/>
            <button @click="gmSetPoints(u.id, u.score, $refs.addPoints[index].value)">Add</button>
          </div>
          <div v-bind:class="{'correct':turn.state == 2 && turn.answers.includes(a)}" 
            v-for="a in u.answers" v-bind:key="u.id + 'a' + a">
            {{ turn.state == 2 ? a : "?" }}
          </div>
        </div>
      </div>
      <div v-if="turn">
        <button v-if="turn.state == 0" class="btn btn-primary" @click="gmLock">Lock</button>
        <div v-else-if="turn.state == 1">
          <button class="btn btn-secondary" @click="gmUnlock">Unlock</button>
          <input v-model="gmAnswers"/>
          <button class="btn btn-primary" @click="gmReveal">Reveal</button>
        </div>
        <button v-else class="btn btn-primary" @click="gmReset">Next Round</button>
      </div>
      <button  class="m-5 btn btn-secondary" @click="gmEdit = !gmEdit">Toggle Edit</button>
    </div>
    <!-- User -->
    <div v-else-if="!user">
      <h1>Welcome to Half-truth!</h1>
      <h2>Coronavirus Edition</h2>
      Name:
      <input v-model="name" v-on:keyup.enter="start"/>
      <button class="btn btn-primary" @click="start">Start</button>
    </div>
    <div v-else>
      <div class="m-4">
        <div>Player: {{ user.id }}</div>
        <div>Score: {{ user.score }}</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col largeletter">
            <div>&nbsp;</div>
            <div v-for="l in ['A', 'B', 'C']" v-bind:key="'l'+l" v-bind:class="{'correct':turn.answers.includes(l)}">{{l}}</div>
          </div>
          <div class="col-8">
            <img class="picture" v-if="turn.picture" :src="turn.picture"/>
          </div>
          <div class="col largeletter">
            <div>&nbsp;</div>
            <div v-for="l in ['F', 'E', 'D']" v-bind:key="'l'+l" v-bind:class="{'correct':turn.answers.includes(l)}">{{l}}</div>
          </div>
        </div>
      </div>
      <div class="m-5">
        <div v-if="answers.length > 3" class="toomany">Only 3 answers maximum</div>
        <div v-if="turn.state">Your answers are locked!</div>
        <div v-for="letter in options" v-bind:key="letter" class="letter">
          <button class="btn" 
            v-bind:class="[ answers.includes(letter) ? 'btn-primary' : 'btn-secondary' ]"
            @click="select(letter)">{{ letter }}</button>
        </div>
      </div>
    </div>
    <!-- Take Picture -->
    <div v-if="takepicture" class="bg">
      <div>Take a picture of the card</div>
      <input type="file" accept="image/*" @change="image1Change"/><br/>
    </div>
  </div>
</template>

<script>
import { storage, db } from '../services/db';
import { getLog } from '../services/log';
let log = getLog('test');

import { readAndCompressImage } from 'browser-image-resizer';

const compressConfig = {
  quality: 0.9,
  maxWidth: 1024,
  maxHeight: 1024,
  autoRotate: true,
  debug: true
};

export default {
  name: 'app',
  data() {
    return {
      gm: false,
      name: null,
      user: null,
      answers: [],

      options: ["A", "B", "C", "D", "E", "F"],

      turn: null,
      users: [],

      takepicture: false, 
      gmAnswers: "",
      gmEdit: false,
    }
  },
  watch: {
    user(u) {
      this.answers = u.answers
    },
    gmAnswers(a) {
      let answers = a.toUpperCase().split("");
      db.collection("GameHT").doc("turn").update({answers:answers});
    }
  },
  mounted() {
    this.gm = this.$route.query.gm;
    this.takepicture = this.$route.query.picture;
    this.$bind("turn", db.collection("GameHT").doc("turn"));
    if (this.gm) {
      log.log("is GM")
      this.$bind("users", db.collection("GameHT/turn/users"));
    }
  },
  methods: {
    start() {
      if (!this.name)
        return;
      log.log("start", this.name);
      db.collection("GameHT/turn/users").doc(this.name).set({score:0, answers:[]});
      this.$bind("user", db.collection("GameHT/turn/users").doc(this.name));
    },
    select(letter) {
      if (this.turn.state != 0)
        return;
      log.log(`adding letter ${letter}`);
      if (this.answers.includes(letter))
        this.answers = this.answers.filter(l => l != letter);
      else
        this.answers.push(letter);
      db.collection("GameHT/turn/users").doc(this.name).update({answers:this.answers});
    },
    gmDelete(uid) {
      db.collection("GameHT/turn/users").doc(uid).delete();
    },
    gmLock() {
      db.collection("GameHT").doc("turn").update({state:1});
    },
    gmUnlock() {
      db.collection("GameHT").doc("turn").update({state:0});
    },
    gmReveal() {
      db.collection("GameHT").doc("turn").update({state:2});
    },
    gmReset() {
      this.gmAnswers = "";
      db.collection("GameHT").doc("turn").update({state:0, picture:"", answers:[]});
      db.collection("GameHT/turn/users").get().then(qs => {
        qs.forEach(u => {
          u.ref.update({answers:[]});
        });
      });
    },
    gmSetPoints(uid, score, points) {
      points = parseInt(points);
      db.collection("GameHT/turn/users").doc(uid).update({score:parseInt(score + points)});
    },
    async image1Change(e) {
      log.log("image1Change");
      let file = (e.target.files || e.dataTransfer.files)[0];
      this.image1 = await readAndCompressImage(file, compressConfig);
      log.log("image1Change compression complete");
      let ref = storage.ref();
      let imageName = `${Math.floor(Math.random() * 1000000000000).toString()}.jpg`;
      ref.child(`gamehtcards/${imageName}`).put(this.image1).then(snapshot => {
          log.log("uploaded file=", imageName);
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            log.log(`downloadURL=${downloadURL}`);
            db.collection("GameHT").doc("turn").set({ picture: downloadURL }, { merge: true });
          });
        });
    },
  }
}
</script>

<style scoped>

.bg {
  background-color: bisque;
}

.midletter {
  font-size: 5vw;
}

.largeletter {
  font-size: 12vw;
}

.correct {
  background: green;
  color: white;
  margin: 1px;
}

.midpicture {
  max-width: 300px;
}

.picture {
  max-width: 100%;
}

.toomany {
  color: crimson;
  font-weight: bold;
}

.user {
  width: 30px;
  height: 150px;
  vertical-align: top;
  display: inline-block;
}

.letter {
  width: 50px;
  display: inline-block;
}

</style>
